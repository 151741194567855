import React from "react";

const Check = () => {
	return (
		<svg
			version="1.1"
			id="Capa_1"
			x="0px"
			y="0px"
			viewBox="0 0 367.805 367.805"
			height="2rem"
			width="2rem"
			style={{ enableBackground: "new 0 0 367.805 367.805" }}
		>
			<g>
				<path
					style={{ fill: "#3BB54A" }}
					d="M183.903,0.001c101.566,0,183.902,82.336,183.902,183.902s-82.336,183.902-183.902,183.902
		S0.001,285.469,0.001,183.903l0,0C-0.288,82.625,81.579,0.29,182.856,0.001C183.205,0,183.554,0,183.903,0.001z"
				/>
				<polygon
					style={{ fill: "#D4E1F4" }}
					points="285.78,133.225 155.168,263.837 82.025,191.217 111.805,161.96 155.168,204.801 
		256.001,103.968 	"
				/>
			</g>
			<g></g>
			<g></g>
			<g></g>
			<g></g>
			<g></g>
			<g></g>
			<g></g>
			<g></g>
			<g></g>
			<g></g>
			<g></g>
			<g></g>
			<g></g>
			<g></g>
			<g></g>
		</svg>
	);
};

export default Check;

import React, { Fragment, useState, useEffect } from "react";
import { useDispatch } from "react-redux";

import { modify_operatingPower_message } from "store/actions/messageActions";
import { power_state } from "store/actions/stateActions";
import { power_value } from "store/actions/valueActions";
import { power_changed } from "store/actions/changedActions";
import { display_error_message } from "store/actions/globalErrorAction";

import heat_pipe_api_url from "constants/index";

const OperatingPower = () => {
	const dispatch = useDispatch();

	const [isInputChange, setIsInputChange] = useState(false);
	const [onFocus, setOnFocus] = useState(false);
	const [unitValue, setUnitValue] = useState("Watts");
	const [inputValue, setInputValue] = useState(null);
	const [isUnitvalueChanged, setIsUnitValueChanged] = useState(false);

	const setPowerInput = (e) => {
		if (inputValue !== e.target.value) {
			setIsInputChange(true);
			setInputValue(e.target.value);
			dispatch(power_changed([{ type: "power", changed: true }]));
		} else setIsInputChange(false);
	};

	const verifyPowerInput = (e) => {
		e.preventDefault();
		setOnFocus(true);
	};

	const modifyUnitValue = (e) => {
		if (unitValue !== e.target.value) {
			setIsUnitValueChanged(true);
			setUnitValue(e.target.value);
			dispatch(power_changed([{ type: "power", changed: true }]));
		} else setIsUnitValueChanged(false);
	};

	const isChanged = isInputChange && onFocus ? true : false;

	useEffect(() => {
		if (isChanged || isUnitvalueChanged)  {
			if(!inputValue || isNaN(inputValue)){
				const messageData = [{ type: "power", message: "Enter the Value for Operating Power." }];
				const stateData = [{ type: "power", state: false }];
				const valueData = [{ type: "power", value: inputValue, unit: unitValue }];

				dispatch(modify_operatingPower_message(messageData));
				dispatch(power_state(stateData));
				dispatch(power_value(valueData));
			}
			else {

				const fetchValidation = async () => {
					const response = await fetch(heat_pipe_api_url + "/OperatingPower", {
						method: "POST",
						headers: {
							"Content-Type": "application/json",
						},
						body: JSON.stringify({
							OperatingPowerLength: inputValue,
							OperatingPowerUnit: unitValue,
						}),
					});

					const resData = await response.json();

					if (response.ok) {
						if (resData.result) {
							const messageData = [{ type: "power", message: resData.messages[0] }];
							const stateData = [{ type: "power", state: false }];
							const valueData = [{ type: "power", value: inputValue, unit: unitValue }];

							dispatch(modify_operatingPower_message(messageData));
							dispatch(power_state(stateData));
							dispatch(power_value(valueData));
							dispatch(display_error_message(false));
						}
						if (!resData.result) {
							const messageData = [{ type: "power", message: "Enter the Value for Operating Power." }];
							const stateData = [{ type: "power", state: true }];
							const valueData = [{ type: "power", value: inputValue, unit: unitValue }];

							dispatch(modify_operatingPower_message(messageData));
							dispatch(power_state(stateData));
							dispatch(power_value(valueData));
							dispatch(display_error_message(false));
						}
					}
					if (!response.ok) {
						dispatch(
							display_error_message("Oops! Unable to Connect with Server. Please Contact Administrator for further help.")
						);
						throw new Error("Error Validating Value.Please try again!");
					}
				};

				fetchValidation().catch((error) => {
					const messageData = [{ type: "power", message: "Error Validating Value. Please try again!" }];
					const stateData = [{ type: "power", state: false }];

					dispatch(modify_operatingPower_message(messageData));
					dispatch(power_state(stateData));
					dispatch(
						display_error_message("Oops! Unable to Connect with Server. Please Contact Administrator for further help.")
					);
				});
			}
		}
	}, [isChanged, inputValue, unitValue, dispatch, isUnitvalueChanged]);

	return (
		<Fragment>
			<form onSubmit={verifyPowerInput}>
				<label htmlFor="operatingPower">Operating Power: </label>
				<input
					id="operatingPower"
					type="number"
					onBlur={verifyPowerInput}
					onChange={setPowerInput}
					required={true}
				/>
				<select>
					<option value="Watts" onChange={modifyUnitValue}>
						Watts
					</option>
				</select>
			</form>
		</Fragment>
	);
};

export default OperatingPower;

import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import InputCollection from "components/inputBox.js/InputCollection";
import MessageBox from "components/messageBox/MessageBox";
import MixedChart from "components/chart/MixedChart";

import {
	condensor_changed,
	evaporator_changed,
	length_changed,
	power_changed,
	temperature_changed,
	diameter_changed,
	orientation_changed,
} from "store/actions/changedActions";

import styles from "App.module.css";

import heat_pipe_api_url from "constants/index";

function App() {
	const dispatch = useDispatch();

	const [applicationState, setApplicationState] = useState();
	const [showGraph, setShowGraph] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [clicked, setClicked] = useState(false);
	const [inputChanged, setInputChanged] = useState(false);
	const [appLoading, setAppLoading] = useState(true);

	const [chartData, setChartData] = useState([]);
	const [error, setError] = useState(false);
	const [forbidden, setForbidden] = useState(false);
	const [globalError, setGlobalError] = useState(false);
	const [globalErrorMessage, setGlobalErrorMessage] = useState(null);

	const stateData = useSelector((state) => state.appState.overallState);
	const valueData = useSelector((state) => state.values.overallValues);
	const changedStatus = useSelector((state) => state.changed.overallChanged);
	const errorMessage = useSelector((state) => state.error.errorMessage);

	let EvaporatorLength,
		EvaporatorUnit,
		CondenserLength,
		CondenserUnit,
		TotalLengthValue,
		TotalLengthUnit,
		OperatingPowerLength,
		OperatingPowerUnit,
		OrientationLength,
		OrientationUnit,
		OperatingTemperatureLength,
		OperatingTemperatureUnit,
		PipeDiameterLength;

	useEffect(() => {
		let currState = [];

		stateData.forEach((el) => {
			currState.push(el.state);
		});
		currState.includes(false) ? setApplicationState(false) : setApplicationState(true);
		if (applicationState === false) setClicked(false);
	}, [stateData, applicationState]);

	useEffect(() => {
		if (errorMessage) {
			setGlobalError(true);
			setGlobalErrorMessage(errorMessage);
		}
	}, [errorMessage]);

	useEffect(() => {
		let changedArray = [];

		changedStatus.forEach((el) => changedArray.push(el.changed));
		changedArray.includes(true) ? setInputChanged(false) : setInputChanged(true);
	}, [changedStatus]);

	useEffect(() => {
		const fetchValidation = async () => {
			const response = await fetch(heat_pipe_api_url + "/Security");

			if (!response.ok) {
				throw new Error("Something Went Wrong!");
			}
			const resData = await response.json();

			resData ? setForbidden(false) : setForbidden(true);
			setAppLoading(false);
		};

		fetchValidation().catch((error) => {
			console.log(error);
			setForbidden(true);
			setAppLoading(false);
		});
	}, []);

	const displayGraph = (e) => {
		e.preventDefault();
		setIsLoading(true);
		setInputChanged(true);

		valueData.forEach((el) => {
			if (el.type === "evaporator") {
				EvaporatorLength = el.value;
				EvaporatorUnit = el.unit;
			}
			if (el.type === "condensor") {
				CondenserLength = el.value;
				CondenserUnit = el.unit;
			}
			if (el.type === "length") {
				TotalLengthValue = el.value;
				TotalLengthUnit = el.unit;
			}
			if (el.type === "power") {
				OperatingPowerLength = el.value;
				OperatingPowerUnit = el.unit;
			}
			if (el.type === "orientation") {
				OrientationLength = el.value;
				OrientationUnit = el.unit;
			}
			if (el.type === "temperature") {
				OperatingTemperatureLength = el.value;
				OperatingTemperatureUnit = el.unit;
			}
			if (el.type === "diameter") {
				PipeDiameterLength = el.value;
			}
		});

		const fetchCalculation = async () => {
			const response = await fetch(heat_pipe_api_url + "/Calculation", {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify({
					EvaporatorLength,
					EvaporatorUnit,
					CondenserLength,
					CondenserUnit,
					TotalLengthValue,
					TotalLengthUnit,
					OperatingPowerLength,
					OperatingPowerUnit,
					OrientationLength,
					OrientationUnit,
					OperatingTemperatureLength,
					OperatingTemperatureUnit,
					PipeDiameterLength,
				}),
			});

			console.log(response);
			
			if (!response.ok) {
				throw new Error("Error Plotting Chart. Please try again!");
			}
			if (response.ok) {
				const resData = await response.json();

				dispatch(condensor_changed([{ type: "condensor", changed: false }]));
				dispatch(evaporator_changed([{ type: "evaporator", changed: false }]));
				dispatch(length_changed([{ type: "length", changed: false }]));
				dispatch(power_changed([{ type: "power", changed: false }]));
				dispatch(temperature_changed([{ type: "temperature", changed: false }]));
				dispatch(diameter_changed([{ type: "diameter", changed: false }]));
				dispatch(orientation_changed([{ type: "orientation", changed: false }]));
				setChartData(resData.chart.series);
				setIsLoading(false);
				setShowGraph(true);
				setClicked(true);
				setError(false);
				setGlobalError(false);
				setGlobalErrorMessage(false);
			}
		};

		fetchCalculation().catch((error) => {
			console.log(error);
			setGlobalError(true);
			setGlobalErrorMessage(
				"Oops! Unable to connect with server at moment. Please contact administrator for further help."
			);
			setIsLoading(false);
			setError(true);
		});
	};

	const spinner = (
		<div className={styles.spinner_box}>
			<div className={styles.loader}></div>
		</div>
	);

	return (
		<div>
			{globalError && (
				<div className={styles.globalError_box}>
					<p className={styles.error}>{globalErrorMessage}</p>
				</div>
			)}
			{forbidden ? (
				<div className={styles.forbidden_box}>
					<p className={styles.forbidden}>This Calculator is not Available</p>
				</div>
			) : (
				<div>
					{appLoading ? (
						<div className={styles.calculator_box}>
							<p className={styles.message_heading}>Your Calculator is Loading. Please Wait...</p>
							{spinner}
						</div>
					) : (
						<div className={styles.app_container}>
							{/* <h1 className={styles.heading}>Boyd Calculator</h1> */}
							<div className={styles.info_box}>
								<InputCollection />
								<MessageBox />
							</div>
							<div className={styles.button_box}>
								<button className={styles.button} disabled={!applicationState} onClick={displayGraph}>
									SUBMIT
								</button>
							</div>

							{isLoading && spinner}
							{!error && showGraph && applicationState && clicked && inputChanged && <MixedChart data={chartData} />}
						</div>
					)}
				</div>
			)}
		</div>
	);
}

export default App;

import React from "react";

import Evaporator from "./Evaporator";
import Condensor from "./Condensor";
import TotalLength from "./TotalLength";
import OperatingPower from "./OperatingPower";
import Orientation from "./Orientation";
import OperatingTemperature from "./OperatingTemperature";
import PipeDiameter from "./PipeDiameter";

import styles from "./InputCollection.module.css";

const InputCollection = () => {
	return (
		<div className={styles.input_box}>
			<Evaporator />
			<Condensor />
			<TotalLength />
			<OperatingPower />
			<Orientation />
			<OperatingTemperature />
			<PipeDiameter />
		</div>
	);
};

export default InputCollection;

import {
	CONDENSOR_STATE,
	EVAPORATOR_STATE,
	POWER_STATE,
	TEMP_STATE,
	ORIENTATION_STATE,
	LENGTH_STATE,
	DIAMETER_STATE,
} from "../actions/stateActions";

const statesArray = [
	{ type: "condensor", state: false },
	{ type: "evaporator", state: false },
	{ type: "power", state: false },
	{ type: "temperature", state: false },
	{ type: "length", state: false },
	{ type: "diameter", state: false },
	{ type: "orientation", state: false },
];

const initialState = {
	overallState: statesArray,
	condensorState: false,
	evaporatorState: false,
	powerState: false,
	temperatureState: false,
	lengthState: false,
	diameterState: false,
	orientationState: false,
};

const stateReducers = (state = initialState, action) => {
	let filteredState;
	switch (action.type) {
		case CONDENSOR_STATE:
			filteredState = state.overallState.filter((el) => el.type !== "condensor");
			return {
				overallState: [...filteredState, ...action.data],
				condensorState: action.data[0].state,
				evaporatorState: state.evaporatorState,
				powerState: state.powerState,
				temperatureState: state.temperatureState,
				lengthState: state.lengthState,
				diameterState: state.diameterState,
				orientationState: state.orientationState,
			};
		case EVAPORATOR_STATE:
			filteredState = state.overallState.filter((el) => el.type !== "evaporator");
			return {
				overallState: [...filteredState, ...action.data],
				condensorState: state.condensorState,
				evaporatorState: action.data[0].state,
				powerState: state.powerState,
				temperatureState: state.temperatureState,
				lengthState: state.lengthState,
				diameterState: state.diameterState,
				orientationState: state.orientationState,
			};
		case POWER_STATE:
			filteredState = state.overallState.filter((el) => el.type !== "power");
			return {
				overallState: [...filteredState, ...action.data],
				condensorState: state.condensorState,
				evaporatorState: state.evaporatorState,
				powerState: action.data[0].state,
				temperatureState: state.temperatureState,
				lengthState: state.lengthState,
				diameterState: state.diameterState,
				orientationState: state.orientationState,
			};
		case TEMP_STATE:
			filteredState = state.overallState.filter((el) => el.type !== "temperature");
			return {
				overallState: [...filteredState, ...action.data],
				condensorState: state.condensorState,
				evaporatorState: state.evaporatorState,
				powerState: state.powerState,
				temperatureState: action.data[0].state,
				lengthState: state.lengthState,
				diameterState: state.diameterState,
				orientationState: state.orientationState,
			};
		case LENGTH_STATE:
			filteredState = state.overallState.filter((el) => el.type !== "length");
			return {
				overallState: [...filteredState, ...action.data],
				condensorState: state.condensorState,
				evaporatorState: state.evaporatorState,
				powerState: state.powerState,
				temperatureState: state.temperatureState,
				lengthState: action.data[0].state,
				diameterState: state.diameterState,
				orientationState: state.orientationState,
			};
		case DIAMETER_STATE:
			filteredState = state.overallState.filter((el) => el.type !== "diameter");
			return {
				overallState: [...filteredState, ...action.data],
				condensorState: state.condensorState,
				evaporatorState: state.evaporatorState,
				powerState: state.powerState,
				temperatureState: state.temperatureState,
				lengthState: state.lengthState,
				diameterState: action.data[0].state,
				orientationState: state.orientationState,
			};
		case ORIENTATION_STATE:
			filteredState = state.overallState.filter((el) => el.type !== "orientation");

			return {
				overallState: [...filteredState, ...action.data],
				condensorState: state.condensorState,
				evaporatorState: state.evaporatorState,
				powerState: state.powerState,
				temperatureState: state.temperatureState,
				lengthState: state.lengthState,
				diameterState: state.diameterState,
				orientationState: action.data[0].state,
			};
		default:
			return state;
	}
};

export default stateReducers;

import { DISPLAY_ERROR } from "../actions/globalErrorAction";

const initialState = {
	errorMessage: false,
};

const messageReducers = (state = initialState, action) => {
	switch (action.type) {
		case DISPLAY_ERROR:
			return {
				errorMessage: action.data,
			};
		default:
			return state;
	}
};

export default messageReducers;

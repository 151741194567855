export const MODIFY_CONDENSOR_MESSAGE = "MODIFY_CONDENSOR_MESSAGE";
export const MODIFY_EVAPORATOR_MESSAGE = "MODIFY_EVAPORATOR_MESSAGE";
export const MODIFY_TOTAL_LENGTH_MESSAGE = "MODIFY_TOTAL_LENGTH_MESSAGE";
export const MODIFY_OPERATING_POWER_MESSAGE = "MODIFY_OPERATING_POWER_MESSAGE";
export const MODIFY_OPERATING_TEMP_MESSAGE = "MODIFY_OPERATING_TEMP_MESSAGE";
export const MODIFY_ORIENTATION_MESSAGE = "MODIFY_ORIENTATION_MESSAGE";

export const modify_condensor_message = (message) => {
	return { type: MODIFY_CONDENSOR_MESSAGE, data: message };
};

export const modify_evaporator_message = (message) => {
	return { type: MODIFY_EVAPORATOR_MESSAGE, data: message };
};

export const modify_totalLength_message = (message) => {
	return { type: MODIFY_TOTAL_LENGTH_MESSAGE, data: message };
};

export const modify_operatingPower_message = (message) => {
	return { type: MODIFY_OPERATING_POWER_MESSAGE, data: message };
};

export const modify_operatingTemparature_message = (message) => {
	return { type: MODIFY_OPERATING_TEMP_MESSAGE, data: message };
};

export const modify_orientation_message = (message) => {
	return { type: MODIFY_ORIENTATION_MESSAGE, data: message };
};

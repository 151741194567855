import React, { Fragment, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { evaporator_state } from "store/actions/stateActions";
import { evaporator_value } from "store/actions/valueActions";
import { modify_evaporator_message } from "store/actions/messageActions";
import { evaporator_changed } from "store/actions/changedActions";

import { condensor_state } from "store/actions/stateActions";
import { condensor_value } from "store/actions/valueActions";
import { modify_condensor_message } from "store/actions/messageActions";

import { length_state } from "store/actions/stateActions";
import { length_value } from "store/actions/valueActions";
import { modify_totalLength_message } from "store/actions/messageActions";

import { display_error_message } from "store/actions/globalErrorAction";

import heat_pipe_api_url from "constants/index";

const Evaporator = () => {
	const dispatch = useDispatch();

	const valuesArray = useSelector((state) => state.values.overallValues);
	let condensorLengthValue, condensorLengthUnit;
	let totalLengthValue, totalLengthUnit;

	valuesArray.forEach((el) => {
		if (el.type === "condensor") {
			condensorLengthValue = el.value;
			condensorLengthUnit = el.unit;
		}

		if (el.type === "length") {
			totalLengthValue = el.value;
			totalLengthUnit = el.unit;
		}
	});
	
	const [isInputChange, setIsInputChange] = useState(false);
	const [onFocus, setOnFocus] = useState(false);
	const [unitValue, setUnitValue] = useState("Millimeters");
	const [inputValue, setInputValue] = useState(null);
	const [isUnitvalueChanged, setIsUnitValueChanged] = useState(false);

	const setEvaporatorInput = (e) => {
		if (inputValue !== e.target.value) {
			setIsInputChange(true);
			setInputValue(e.target.value);
			dispatch(evaporator_changed([{ type: "evaporator", changed: true }]));
		} else setIsInputChange(false);
	};

	const verifyEvaporatorInput = (e) => {
		e.preventDefault();
		setOnFocus(true);
	};

	const modifyUnitValue = (e) => {
		if (unitValue !== e.target.value) {
			setIsUnitValueChanged(true);
			setUnitValue(e.target.value);
			dispatch(evaporator_changed([{ type: "evaporator", changed: true }]));
		} else setIsUnitValueChanged(false);
	};

	const isChanged = isInputChange && onFocus ? true : false;

	useEffect(() => {
		if (isChanged || isUnitvalueChanged)  {
			if(!inputValue || isNaN(inputValue)){
				const evpMessageData = [{ type: "evaporator", message: "Enter the value for Evaporator" }];
				const evp_stateData = [{ type: "evaporator", state: false }];
				const evp_valueData = [{ type: "evaporator", value: inputValue, unit: unitValue }];

				dispatch(evaporator_state(evp_stateData));
				dispatch(evaporator_value(evp_valueData));
				dispatch(modify_evaporator_message(evpMessageData));
			}
			else{
				const fetchValidation = async () => {
					const response = await fetch(heat_pipe_api_url + "/Evaporator", {
						method: "POST",
						headers: {
							"Content-Type": "application/json",
						},
						body: JSON.stringify({
							EvaporatorLength: inputValue,
							EvaporatorUnit: unitValue,
							CondenserLength: condensorLengthValue || null,
							CondenserUnit: condensorLengthUnit,
							TotalLengthValue: totalLengthValue || null,
							TotalLengthUnit: totalLengthUnit,
						}),
					});
	
					const resData = await response.json();
					
					if (response.ok) {
						const evpMessage = ( resData.messages[0] == "" || !inputValue || isNaN(inputValue) ) ? "Enter the value for Evaporator"  :  resData.messages[0];
						const evpMessageData = [{ type: "evaporator", message: evpMessage }];
						const evp_stateData = [{ type: "evaporator", state: resData.messages[0] == "" }];
						const evp_valueData = [{ type: "evaporator", value: inputValue, unit: unitValue }];
						dispatch(evaporator_state(evp_stateData));
						dispatch(evaporator_value(evp_valueData));
						dispatch(modify_evaporator_message(evpMessageData));
	
						if(condensorLengthValue && isNaN(condensorLengthValue) == false) {
							const condMessage = resData.messages[1] == "" ? "Enter the value for Condenser" : resData.messages[1];
							const condMessageData = [{ type: "condensor", message: condMessage }];
							const cdn_stateData = [{ type: "condensor", state: resData.messages[1] == "" }];
							const cdn_valueData = [{ type: "condensor", value: condensorLengthValue, unit: condensorLengthUnit }];
							dispatch(condensor_state(cdn_stateData));
							dispatch(condensor_value(cdn_valueData));
							dispatch(modify_condensor_message(condMessageData));
						}
	
						if(totalLengthValue && isNaN(totalLengthValue) == false) {
							const lengthMessage = resData.messages[2] == "" ? "Enter the value for Total Length" : resData.messages[2];
							const lengthMessageData = [{ type: "length", message: lengthMessage }];
							const len_stateData = [{ type: "length", state: resData.messages[2] == "" }];
							const len_valueData = [{ type: "length", value: totalLengthValue, unit: totalLengthUnit }];
							dispatch(length_state(len_stateData));
							dispatch(length_value(len_valueData));
							dispatch(modify_totalLength_message(lengthMessageData));
						}
	
						dispatch(display_error_message(false));
					}
					if (!response.ok) {
						dispatch(
							display_error_message("Oops! Unable to Connect with Server. Please Contact Administrator for further help.")
						);
						throw new Error("Error Validating Value.Please try again!");
					}
				};
				fetchValidation().catch((error) => {
					const messageData = [{ type: "evaporator", message: "Error Validating Value. Please try again!" }];
					const stateData = [{ type: "evaporator", state: false }];
	
					dispatch(modify_evaporator_message(messageData));
					dispatch(evaporator_state(stateData));
					dispatch(
						display_error_message("Oops! Unable to Connect with Server. Please Contact Administrator for further help.")
					);
				});
			}
			
		}
	}, [isChanged, inputValue, unitValue, dispatch, isUnitvalueChanged]);

	return (
		<Fragment>
			<form onSubmit={verifyEvaporatorInput}>
				<label htmlFor="evaporator">Evaporator: </label>
				<input
					id="evaporator"
					type="number"
					onChange={setEvaporatorInput}
					onBlur={verifyEvaporatorInput}
					required={true}
				/>
				<select onChange={modifyUnitValue}>
					<option value="Millimeters">Millimeter</option>
					<option value="Centimeters">Centimeter</option>
					<option value="Inches">Inch</option>
					<option value="Meters">Meter</option>
					<option value="Feet">Feet</option>
				</select>
			</form>
		</Fragment>
	);
};

export default Evaporator;

import {
	CONDENSOR_VALUE,
	EVAPORATOR_VALUE,
	POWER_VALUE,
	TEMP_VALUE,
	LENGTH_VALUE,
	DIAMETER_VALUE,
	ORIENTATION_VALUE,
} from "../actions/valueActions";

const initialState = {
	overallValues: [],
};

const valueReducers = (state = initialState, action) => {
	let filteredValues;
	switch (action.type) {
		case CONDENSOR_VALUE:
			filteredValues = state.overallValues.filter((el) => el.type !== "condensor");
			return {
				overallValues: [...filteredValues, ...action.data],
			};
		case EVAPORATOR_VALUE:
			filteredValues = state.overallValues.filter((el) => el.type !== "evaporator");
			return {
				overallValues: [...filteredValues, ...action.data],
			};
		case POWER_VALUE:
			filteredValues = state.overallValues.filter((el) => el.type !== "power");
			return {
				overallValues: [...filteredValues, ...action.data],
			};
		case TEMP_VALUE:
			filteredValues = state.overallValues.filter((el) => el.type !== "temperature");
			return {
				overallValues: [...filteredValues, ...action.data],
			};
		case LENGTH_VALUE:
			filteredValues = state.overallValues.filter((el) => el.type !== "length");
			return {
				overallValues: [...filteredValues, ...action.data],
			};
		case DIAMETER_VALUE:
			filteredValues = state.overallValues.filter((el) => el.type !== "diameter");
			return {
				overallValues: [...filteredValues, ...action.data],
			};
		case ORIENTATION_VALUE:
			filteredValues = state.overallValues.filter((el) => el.type !== "orientation");

			return {
				overallValues: [...filteredValues, ...action.data],
			};
		default:
			return state;
	}
};

export default valueReducers;

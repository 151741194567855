import React, { Fragment, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { modify_orientation_message } from "store/actions/messageActions";
import { orientation_state } from "store/actions/stateActions";
import { orientation_value } from "store/actions/valueActions";
import { orientation_changed } from "store/actions/changedActions";
import { display_error_message } from "store/actions/globalErrorAction";

import heat_pipe_api_url from "constants/index";

const Orientation = () => {
	const dispatch = useDispatch();

	const valuesArray = useSelector((state) => state.values.overallValues);
	let totalLengthValue, totalLengthUnit;

	valuesArray.forEach((el) => {
		if (el.type === "length") {
			totalLengthValue = el.value;
			totalLengthUnit = el.unit;
		}
	});

	const [isInputChange, setIsInputChange] = useState(false);
	const [onFocus, setOnFocus] = useState(false);
	const [unitValue, setUnitValue] = useState("Degree");
	const [inputValue, setInputValue] = useState(null);
	const [isUnitvalueChanged, setIsUnitValueChanged] = useState(false);

	const setOrientationInput = (e) => {
		if (inputValue !== e.target.value) {
			setIsInputChange(true);
			setInputValue(e.target.value);
			dispatch(orientation_changed([{ type: "orientation", changed: true }]));
		} else setIsInputChange(false);
	};

	const verifyOrientationInput = (e) => {
		e.preventDefault();
		setOnFocus(true);
	};

	const modifyUnitValue = (e) => {
		if (unitValue !== e.target.value) {
			setIsUnitValueChanged(true);
			setUnitValue(e.target.value);
			dispatch(orientation_changed([{ type: "orientation", changed: true }]));
		} else setIsUnitValueChanged(false);
	};

	const isChanged = isInputChange && onFocus ? true : false;

	useEffect(() => {
		if (isChanged || isUnitvalueChanged)  {
			if(!inputValue || isNaN(inputValue)){
				const messageData = [{ type: "orientation", message: "Enter the value for Orientation"  }];
				const stateData = [{ type: "orientation", state: false }];
				const valueData = [{ type: "orientation", value: inputValue, unit: unitValue }];

				dispatch(modify_orientation_message(messageData));
				dispatch(orientation_state(stateData));
				dispatch(orientation_value(valueData));
			}
			else {
				const fetchValidation = async () => {
					const response = await fetch(heat_pipe_api_url + "/Orientation", {
						method: "POST",
						headers: {
							"Content-Type": "application/json",
						},
						body: JSON.stringify({
							OrientationLength: inputValue,
							OrientationUnit: unitValue,
							TotalLengthValue: totalLengthValue || null,
							TotalLengthUnit: totalLengthUnit,
						}),
					});

					const resData = await response.json();

					if (response.ok) {
						if (resData.result) {
							const messageData = [{ type: "orientation", message: resData.messages[0] }];
							const stateData = [{ type: "orientation", state: false }];
							const valueData = [{ type: "orientation", value: inputValue, unit: unitValue }];

							dispatch(modify_orientation_message(messageData));
							dispatch(orientation_state(stateData));
							dispatch(orientation_value(valueData));
							dispatch(display_error_message(false));
						}
						if (!resData.result) {
							const messageData = [{ type: "orientation", message: "Enter the value for Orientation" }];
							const stateData = [{ type: "orientation", state: true }];
							const valueData = [{ type: "orientation", value: inputValue, unit: unitValue }];

							dispatch(modify_orientation_message(messageData));
							dispatch(orientation_state(stateData));
							dispatch(orientation_value(valueData));
							dispatch(display_error_message(false));
						}
					}
					if (!response.ok) {
						dispatch(
							display_error_message("Oops! Unable to Connect with Server. Please Contact Administrator for further help.")
						);
						throw new Error("Error Validating Value.Please try again!");
					}
				};

				fetchValidation().catch((error) => {
					const messageData = [{ type: "orientation", message: "Error Validating Value. Please try again!" }];
					const stateData = [{ type: "orientation", state: false }];

					dispatch(modify_orientation_message(messageData));
					dispatch(orientation_state(stateData));
					dispatch(
						display_error_message("Oops! Unable to Connect with Server. Please Contact Administrator for further help.")
					);
				});
			}
		}
	}, [isChanged, inputValue, unitValue, dispatch, isUnitvalueChanged, totalLengthValue, totalLengthUnit]);

	return (
		<Fragment>
			<form onSubmit={verifyOrientationInput}>
				<label htmlFor="orientation">Orientation: </label>
				<input
					id="orientation"
					type="number"
					onChange={setOrientationInput}
					onBlur={verifyOrientationInput}
					required={true}
				/>
				<select onChange={modifyUnitValue}>
					<option value="Degree">Degree</option>
					<option value="Millimeters">Millimeter</option>
					<option value="Meters">Meters</option>
				</select>
			</form>
		</Fragment>
	);
};

export default Orientation;

import {
	CONDENSOR_CHANGED,
	EVAPORATOR_CHANGED,
	POWER_CHANGED,
	TEMP_CHANGED,
	LENGTH_CHANGED,
	DIAMETER_CHANGED,
	ORIENTATION_CHANGED,
} from "../actions/changedActions";

const changedStatesArray = [
	{ type: "condensor", changed: false },
	{ type: "evaporator", changed: false },
	{ type: "power", changed: false },
	{ type: "temperature", changed: false },
	{ type: "length", changed: false },
	{ type: "diameter", changed: false },
	{ type: "orientation", changed: false },
];

const initialState = {
	overallChanged: changedStatesArray,
};

const changedReducers = (state = initialState, action) => {
	let filteredArray;
	switch (action.type) {
		case CONDENSOR_CHANGED:
			filteredArray = state.overallChanged.filter((el) => el.type !== "condensor");
			return {
				overallChanged: [...filteredArray, ...action.data],
			};
		case EVAPORATOR_CHANGED:
			filteredArray = state.overallChanged.filter((el) => el.type !== "evaporator");
			return {
				overallChanged: [...filteredArray, ...action.data],
			};
		case POWER_CHANGED:
			filteredArray = state.overallChanged.filter((el) => el.type !== "power");
			return {
				overallChanged: [...filteredArray, ...action.data],
			};
		case TEMP_CHANGED:
			filteredArray = state.overallChanged.filter((el) => el.type !== "temperature");
			return {
				overallChanged: [...filteredArray, ...action.data],
			};
		case LENGTH_CHANGED:
			filteredArray = state.overallChanged.filter((el) => el.type !== "length");
			return {
				overallChanged: [...filteredArray, ...action.data],
			};
		case DIAMETER_CHANGED:
			filteredArray = state.overallChanged.filter((el) => el.type !== "diameter");
			return {
				overallChanged: [...filteredArray, ...action.data],
			};
		case ORIENTATION_CHANGED:
			filteredArray = state.overallChanged.filter((el) => el.type !== "orientation");

			return {
				overallChanged: [...filteredArray, ...action.data],
			};
		default:
			return state;
	}
};

export default changedReducers;

import React from "react";
import ReactDOM from "react-dom";
import "index.css";
import App from "App";

import { Provider } from "react-redux";
import ReduxThunk from "redux-thunk";
import { createStore, applyMiddleware, combineReducers } from "redux";

import messageReducers from "store/reducers/messageReducer";
import stateReducers from "store/reducers/stateReducer";
import valueReducers from "store/reducers/valueReducers";
import changedReducers from "store/reducers/changedReducer";
import globalErrorReducer from "store/reducers/globalErrorReducer";

const rootReducer = combineReducers({
	messages: messageReducers,
	appState: stateReducers,
	values: valueReducers,
	changed: changedReducers,
	error: globalErrorReducer,
});

const store = createStore(rootReducer, applyMiddleware(ReduxThunk));

ReactDOM.render(
	<Provider store={store}>
		<App />
	</Provider>,
	document.getElementById("root")
);

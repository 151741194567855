import React, { useEffect, useRef } from "react";
import { Chart } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";

import styles from "./Mixed.module.css";

const MixedChart = (props) => {
	const chartCanvas = useRef(null);

	const data = props.data;

	const spline_x_values = [];
	const performance_main = [];
	const performance_summary = [];
	const powerGoal_Series = [];
	const operatingPoint_Series = [];

	if (data[0].items.length > 0) {
		data[0].items.forEach((el) => {
			spline_x_values.push(+el.x_value);
			const obj = {
				x: +el.x_value,
				y: +el.y_value,
			};

			performance_main.push(obj);
		});
	}

	if (data[1].items.length > 0) {
		data[1].items.forEach((el) => {
			spline_x_values.push(+el.x_value);
			const obj = {
				x: +el.x_value,
				y: +el.y_value,
			};

			performance_summary.push(obj);
		});
	}

	if (data[2].items.length > 0) {
		data[2].items.forEach((el, i) => {
			const obj = {
				x: +el.x_value,
				y: +el.y_value,
			};

			powerGoal_Series.push(obj);
		});
	}

	if (data[3].items.length > 0) {
		data[3].items.forEach((el) => {
			const obj = {
				x: +el.x_value,
				y: +el.y_value,
			};

			operatingPoint_Series.push(obj);
		});
	}

	const configureChart = () => {
		Chart.Legend.prototype.afterFit = function () {
			this.height = this.height + 20;
		};
		new Chart(chartCanvas.current, {
			plugins: [ChartDataLabels],
			type: "scatter",
			data: {
				datasets: [
					{
						label: "",
						data: performance_main,
						type: 'line',
						borderColor: "green",
						fill: false,
						pointRadius: 0,
						pointHoverRadius: 0,
						pointHitRadius: 0,
						tension: 0,
						showLine: true,
						datalabels: {
							font: {
								size: 0,
							},	
						}
					},
					{
						label: "Performance",
						data: performance_summary,
						borderColor: "green",
						backgroundColor: "green",
						fill: false,
						pointRadius: 4,
						pointHoverRadius: 6,
						pointHitRadius: 0,
						tension: 0,
						showLine: false,
						datalabels: {
							color: "green",
							anchor: "start",
							align: "top",
							padding: 10,
							font: {
								size: 15,
								//weight: "bold",
							},
						},
					},
					{
						label: "Power Goal",
						data: powerGoal_Series,
						borderColor: "#1c8bd4",
						backgroundColor: "#1c8bd4",
						fill: false,
						tension: 0,
						pointRadius: 4,
						pointHoverRadius: 6,
						pointStyle: "circle",
						showLine: true,
						datalabels: {
							color: "#1c8bd4",
							anchor: "start",
							align: "bottom",
							font: {
								size: 15,
								//weight: "bold",
							},
						},
					},
					{
						label: "Operating Point",
						data: operatingPoint_Series,
						pointRadius: 4,
						pointHoverRadius: 6,
						pointStyle: "circle",
						borderColor: "red",
						backgroundColor: "red",
						pointBackgroundColor: "red",
						fill: false,
						tension: 0,
						pointBorderColor: "red",
						showLine: true,
						datalabels: {
							color: "red",
							anchor: "start",
							align: "top",
							padding: 10,
							font: {
								size: 15,
								//weight: "bold",
							},
						},
					},
				],
			},
			options: {
				responsive: false,
				maintainAspectRatio: true,
				//aspectRatio: 5 / 3,
				title: {
					display: true,
					text: "Heat Pipe Performance Chart",
					fontSize: 20,
					padding: 20,
				},
				legend: {
					labels: {
						fontSize: 15,
						padding: 30,
						fontStyle: "bold",
						filter: item => {
							return item.text != ""
						}
					},
				},
				plugins: {
					datalabels: {
						formatter: function (value, context) {
							return value.y;
						},
					},
				},
				tooltips: {
					titleFontSize: 15,
					bodyFontSize: 15
				},
				scales: {
					xAxes: [
						{
							scaleLabel: {
								display: true,
								labelString: "Operating Temperature (°C)",
								fontSize: 15,
								fontStyle: "bold",
								padding: 10,
							},

							ticks: {
								min: 0,
								suggestedMax: 225,
								fontSize: 15,
								stepSize: 20,
							},
						},
					],
					yAxes: [
						{
							scaleLabel: {
								display: true,
								labelString: "Power (Watts)",
								fontSize: 15,
								fontStyle: "bold",
								padding: 10,
							},
							ticks: {
								fontSize: 15,
							},
						},
					],
				},
			},
		});
	};

	useEffect(() => {
		configureChart();
	});

	return (
		<div className={styles.layout}>
			<canvas className={styles.chart} ref={chartCanvas} />
		</div>
	);
};

export default MixedChart;

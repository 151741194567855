export const CONDENSOR_VALUE = "CONDENSOR_VALUE";
export const EVAPORATOR_VALUE = "EVAPORATOR_VALUE";
export const LENGTH_VALUE = "LENGTH_VALUE";
export const POWER_VALUE = "POWER_VALUE";
export const TEMP_VALUE = "TEMP_VALUE";
export const DIAMETER_VALUE = "DIAMETER_VALUE";
export const ORIENTATION_VALUE = "ORIENTATION_VALUE";

export const condensor_value = (stateData) => {
	return { type: CONDENSOR_VALUE, data: stateData };
};

export const evaporator_value = (stateData) => {
	return { type: EVAPORATOR_VALUE, data: stateData };
};

export const length_value = (stateData) => {
	return { type: LENGTH_VALUE, data: stateData };
};

export const power_value = (stateData) => {
	return { type: POWER_VALUE, data: stateData };
};

export const temperature_value = (stateData) => {
	return { type: TEMP_VALUE, data: stateData };
};

export const diameter_value = (stateData) => {
	return { type: DIAMETER_VALUE, data: stateData };
};

export const orientation_value = (stateData) => {
	return { type: ORIENTATION_VALUE, data: stateData };
};

export const CONDENSOR_CHANGED = "CONDENSOR_CHANGED";
export const EVAPORATOR_CHANGED = "EVAPORATOR_CHANGED";
export const LENGTH_CHANGED = "LENGTH_CHANGED";
export const POWER_CHANGED = "POWER_CHANGED";
export const TEMP_CHANGED = "TEMP_CHANGED";
export const DIAMETER_CHANGED = "DIAMETER_CHANGED";
export const ORIENTATION_CHANGED = "ORIENTATION_CHANGED";

export const condensor_changed = (stateData) => {
	return { type: CONDENSOR_CHANGED, data: stateData };
};

export const evaporator_changed = (stateData) => {
	return { type: EVAPORATOR_CHANGED, data: stateData };
};

export const length_changed = (stateData) => {
	return { type: LENGTH_CHANGED, data: stateData };
};

export const power_changed = (stateData) => {
	return { type: POWER_CHANGED, data: stateData };
};

export const temperature_changed = (stateData) => {
	return { type: TEMP_CHANGED, data: stateData };
};

export const diameter_changed = (stateData) => {
	return { type: DIAMETER_CHANGED, data: stateData };
};

export const orientation_changed = (stateData) => {
	return { type: ORIENTATION_CHANGED, data: stateData };
};

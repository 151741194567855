export const CONDENSOR_STATE = "CONDENSOR_STATE";
export const EVAPORATOR_STATE = "EVAPORATOR_STATE";
export const LENGTH_STATE = "LENGTH_STATE";
export const POWER_STATE = "POWER_STATE";
export const TEMP_STATE = "TEMP_STATE";
export const DIAMETER_STATE = "DIAMETER_STATE";
export const ORIENTATION_STATE = "ORIENTATION_STATE";

export const condensor_state = (stateData) => {
	return { type: CONDENSOR_STATE, data: stateData };
};

export const evaporator_state = (stateData) => {
	return { type: EVAPORATOR_STATE, data: stateData };
};

export const length_state = (stateData) => {
	return { type: LENGTH_STATE, data: stateData };
};

export const power_state = (stateData) => {
	return { type: POWER_STATE, data: stateData };
};

export const temperature_state = (stateData) => {
	return { type: TEMP_STATE, data: stateData };
};

export const diameter_state = (stateData) => {
	return { type: DIAMETER_STATE, data: stateData };
};

export const orientation_state = (stateData) => {
	return { type: ORIENTATION_STATE, data: stateData };
};

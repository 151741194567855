import {
	MODIFY_CONDENSOR_MESSAGE,
	MODIFY_EVAPORATOR_MESSAGE,
	MODIFY_OPERATING_POWER_MESSAGE,
	MODIFY_OPERATING_TEMP_MESSAGE,
	MODIFY_ORIENTATION_MESSAGE,
	MODIFY_TOTAL_LENGTH_MESSAGE,
} from "../actions/messageActions";

const messageArray = [
	{ type: "evaporator", message: "Enter the Value for Evaporator" },
	{ type: "condensor", message: "Enter the Value for Condenser" },
	{ type: "length", message: "Enter the Value for Total Length " },
	{ type: "power", message: "Enter the Value for Operating Power" },
	{ type: "orientation", message: "Enter the Value for Orientation" },
	{ type: "temperature", message: "Enter the Value for Operating Temperature" },
	{ type: "diameter", message: "Select the Value for Pipe Diameter" },
];

const initialState = {
	messageArray: messageArray,
};

const messageReducers = (state = initialState, action) => {
	let filteredMessage;
	switch (action.type) {
		case MODIFY_CONDENSOR_MESSAGE:
			filteredMessage = state.messageArray.filter((el) => el.type !== "condensor");
			return {
				messageArray: [...filteredMessage, ...action.data],
			};
		case MODIFY_EVAPORATOR_MESSAGE:
			filteredMessage = state.messageArray.filter((el) => el.type !== "evaporator");
			return {
				messageArray: [...filteredMessage, ...action.data],
			};
		case MODIFY_OPERATING_POWER_MESSAGE:
			filteredMessage = state.messageArray.filter((el) => el.type !== "power");
			return {
				messageArray: [...filteredMessage, ...action.data],
			};
		case MODIFY_OPERATING_TEMP_MESSAGE:
			filteredMessage = state.messageArray.filter((el) => el.type !== "temperature");
			return {
				messageArray: [...filteredMessage, ...action.data],
			};
		case MODIFY_TOTAL_LENGTH_MESSAGE:
			filteredMessage = state.messageArray.filter((el) => el.type !== "length");
			return {
				messageArray: [...filteredMessage, ...action.data],
			};
		case MODIFY_ORIENTATION_MESSAGE:
			filteredMessage = state.messageArray.filter((el) => el.type !== "orientation");
			return {
				messageArray: [...filteredMessage, ...action.data],
			};
		default:
			return state;
	}
};

export default messageReducers;

import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import styles from "./MessageBox.module.css";
import Check from "../svg/Check";
import Remove from "../svg/Remove";

const MessageBox = () => {
	const arrayMessages = useSelector((state) => state.messages.messageArray);
	const stateData = useSelector((state) => state.appState.overallState);
	const condensorState = useSelector((state) => state.appState.condensorState);
	const evaporatorState = useSelector((state) => state.appState.evaporatorState);
	const powerState = useSelector((state) => state.appState.powerState);
	const lengthState = useSelector((state) => state.appState.lengthState);
	const diameterState = useSelector((state) => state.appState.diameterState);
	const orientationState = useSelector((state) => state.appState.orientationState);
	const tempState = useSelector((state) => state.appState.temperatureState);

	const [applicationState, setApplicationState] = useState();

	let condensorMessage,
		evaporatorMessage,
		tempMessage,
		lengthMessage,
		powerMessage,
		diameterMessage,
		orientationMessage;

	useEffect(() => {
		let currState = [];

		stateData.forEach((el) => {
			currState.push(el.state);
		});
		currState.includes(false) ? setApplicationState(false) : setApplicationState(true);
	}, [stateData]);

	arrayMessages.forEach((el) => {
		switch (el.type) {
			case "condensor":
				return (condensorMessage = el.message);
			case "evaporator":
				return (evaporatorMessage = el.message);
			case "power":
				return (powerMessage = el.message);
			case "temperature":
				return (tempMessage = el.message);
			case "length":
				return (lengthMessage = el.message);
			case "diameter":
				return (diameterMessage = el.message);
			case "orientation":
				return (orientationMessage = el.message);
			default:
				return;
		}
	});

	const borderStyle = applicationState ? styles.border_success : styles.border_fail;

	return (
		<div className={`${styles.message_box} ${borderStyle}`}>
			<p className={`${evaporatorState ? styles.para_success : styles.para_fail} ${styles.paragraph}`}>
				<span className={styles.span}>{evaporatorState ? <Check /> : <Remove />}</span>
				{evaporatorMessage}
			</p>
			<p className={`${condensorState ? styles.para_success : styles.para_fail} ${styles.paragraph}`}>
				<span className={styles.span}>{condensorState ? <Check /> : <Remove />}</span>
				{condensorMessage}
			</p>
			<p className={`${lengthState ? styles.para_success : styles.para_fail} ${styles.paragraph}`}>
				<span className={styles.span}>{lengthState ? <Check /> : <Remove />}</span>
				{lengthMessage}
			</p>
			<p className={`${powerState ? styles.para_success : styles.para_fail} ${styles.paragraph}`}>
				<span className={styles.span}>{powerState ? <Check /> : <Remove />}</span>
				{powerMessage}
			</p>
			<p className={`${orientationState ? styles.para_success : styles.para_fail} ${styles.paragraph}`}>
				<span className={styles.span}>{orientationState ? <Check /> : <Remove />}</span>
				{orientationMessage}
			</p>
			<p className={`${tempState ? styles.para_success : styles.para_fail} ${styles.paragraph}`}>
				<span className={styles.span}>{tempState ? <Check /> : <Remove />}</span>
				{tempMessage}
			</p>
			<p className={`${diameterState ? styles.para_success : styles.para_fail} ${styles.paragraph}`}>
				<span className={styles.span}>{diameterState ? <Check /> : <Remove />}</span>
				{diameterMessage}
			</p>
		</div>
	);
};

export default MessageBox;

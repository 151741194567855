import React, { Fragment, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { modify_condensor_message } from "store/actions/messageActions";

import { evaporator_state } from "store/actions/stateActions";
import { evaporator_value } from "store/actions/valueActions";
import { modify_evaporator_message } from "store/actions/messageActions";


import { condensor_state } from "store/actions/stateActions";
import { condensor_value } from "store/actions/valueActions";
import { condensor_changed } from "store/actions/changedActions";

import { length_state } from "store/actions/stateActions";
import { length_value } from "store/actions/valueActions";
import { modify_totalLength_message } from "store/actions/messageActions";

import { display_error_message } from "store/actions/globalErrorAction";

import heat_pipe_api_url from "constants/index";

const Condensor = () => {
	const dispatch = useDispatch();

	const valuesArray = useSelector((state) => state.values.overallValues);
	let evaporatorLengthValue, evaporatorLengthUnit;
	let totalLengthValue, totalLengthUnit;

	valuesArray.forEach((el) => {
		if (el.type === "evaporator") {
			evaporatorLengthValue = el.value;
			evaporatorLengthUnit = el.unit;
		}

		if (el.type === "length") {
			totalLengthValue = el.value;
			totalLengthUnit = el.unit;
		}
	});

	const [isInputChange, setIsInputChange] = useState(false);
	const [onFocus, setOnFocus] = useState(false);
	const [unitValue, setUnitValue] = useState("Millimeters");
	const [isUnitvalueChanged, setIsUnitValueChanged] = useState(false);
	const [inputValue, setInputValue] = useState(null);

	const setCondensorInput = (e) => {
		if (inputValue !== e.target.value) {
			setIsInputChange(true);
			setInputValue(e.target.value);
			dispatch(condensor_changed([{ type: "condensor", changed: true }]));
		} else setIsInputChange(false);
	};

	const verifyCondensorInput = (e) => {
		e.preventDefault();
		setOnFocus(true);
	};

	const modifyUnitValue = (e) => {
		if (unitValue !== e.target.value) {
			setIsUnitValueChanged(true);
			setUnitValue(e.target.value);
			dispatch(condensor_changed([{ type: "condensor", changed: true }]));
		} else setIsUnitValueChanged(false);
	};

	const isChanged = isInputChange && onFocus ? true : false;

	useEffect(() => {
		if (isChanged || isUnitvalueChanged)  {
			if(!inputValue || isNaN(inputValue)){
				const messageData = [{ type: "condensor", message: "Enter the value for Condenser" }];
				const stateData = [{ type: "condensor", state: false }];
				const valueData = [{ type: "condensor", value: inputValue, unit: unitValue }];

				dispatch(condensor_state(stateData));
				dispatch(condensor_value(valueData));
				dispatch(modify_condensor_message(messageData));
			}
			else{

				const fetchValidation = async () => {
					const response = await fetch(heat_pipe_api_url + "/Condenser", {
						method: "POST",
						headers: {
							"Content-Type": "application/json",
						},
						body: JSON.stringify({
							CondenserLength: inputValue,
							CondenserUnit: unitValue,
							TotalLengthValue: totalLengthValue || null,
							TotalLengthUnit: totalLengthUnit,
							EvaporatorLength : evaporatorLengthValue || null,
							EvaporatorUnit: evaporatorLengthUnit,
						}),
					});
	
					const resData = await response.json();
	
					if (response.ok) {
						const cndMessage = ( resData.messages[1] == "" || !inputValue || isNaN(inputValue) ) ? "Enter the value for Condenser"  :  resData.messages[1];
						const messageData = [{ type: "condensor", message: cndMessage }];
						const stateData = [{ type: "condensor", state: resData.messages[1] == "" }];
						const valueData = [{ type: "condensor", value: inputValue, unit: unitValue }];
	
						dispatch(condensor_state(stateData));
						dispatch(condensor_value(valueData));
						dispatch(modify_condensor_message(messageData));
						
						if(evaporatorLengthValue && isNaN(evaporatorLengthValue) == false) {
							const evpMessage = resData.messages[0] == "" ? "Enter the value for Evaporator" : resData.messages[0];
							const evpMessageData = [{ type: "evaporator", message: evpMessage }];
							const evp_stateData = [{ type: "evaporator", state: resData.messages[0] == "" }];
							const evp_valueData = [{ type: "evaporator", value: evaporatorLengthValue, unit: evaporatorLengthUnit }];
							dispatch(evaporator_state(evp_stateData));
							dispatch(evaporator_value(evp_valueData));
							dispatch(modify_evaporator_message(evpMessageData));
						}
						
						if(totalLengthValue && isNaN(totalLengthValue) == false) {
							const lengthMessage = resData.messages[2] == "" ? "Enter the value for Total Length" : resData.messages[2];
							const lengthMessageData = [{ type: "length", message: lengthMessage }];
							const len_stateData = [{ type: "length", state: resData.messages[2] == "" }];
							const len_valueData = [{ type: "length", value: totalLengthValue, unit: totalLengthUnit }];
							dispatch(length_state(len_stateData));
							dispatch(length_value(len_valueData));
							dispatch(modify_totalLength_message(lengthMessageData));
						}
						
						dispatch(display_error_message(false));
					}
					if (!response.ok) {
						dispatch(
							display_error_message("Oops! Unable to Connect with Server. Please Contact Administrator for further help.")
						);
						throw new Error("Error Validating Value.Please try again!");
					}
				};

				fetchValidation().catch((error) => {
					const messageData = [
						{
							type: "condensor",
							message: "Error Validating Value. Please try again!",
						},
					];
	
					const stateData = [{ type: "condensor", state: false }];
	
					dispatch(modify_condensor_message(messageData));
					dispatch(condensor_state(stateData));
					dispatch(
						display_error_message("Oops! Unable to Connect with Server. Please Contact Administrator for further help.")
					);
				});
			}
		}
	}, [isChanged, inputValue, unitValue, dispatch, isUnitvalueChanged]);

	return (
		<Fragment>
			<form onSubmit={verifyCondensorInput}>
				<label htmlFor="condensor">Condenser: </label>
				<input
					onChange={setCondensorInput}
					onBlur={verifyCondensorInput}
					id="condensor"
					type="number"
					min="0"
					max="65535"
					required={true}
				/>
				<select onChange={modifyUnitValue}>
					<option value="Millimeters">Millimeter</option>
					<option value="Centimeters">Centimeter</option>
					<option value="Inches">Inch</option>
					<option value="Meters">Meter</option>
					<option value="Feet">Feet</option>
				</select>
			</form>
		</Fragment>
	);
};

export default Condensor;

import React, { Fragment, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { modify_operatingTemparature_message } from "store/actions/messageActions";
import { temperature_state } from "store/actions/stateActions";
import { temperature_value } from "store/actions/valueActions";
import { temperature_changed } from "store/actions/changedActions";
import { display_error_message } from "store/actions/globalErrorAction";

import heat_pipe_api_url from "constants/index";

const OperatingTemperature = () => {
	const dispatch = useDispatch();

	const [isInputChange, setIsInputChange] = useState(false);
	const [onFocus, setOnFocus] = useState(false);
	const [unitValue, setUnitValue] = useState("Celsius");
	const [inputValue, setInputValue] = useState(null);
	const [isUnitvalueChanged, setIsUnitValueChanged] = useState(false);

	const setTemperatureInput = (e) => {
		if (inputValue !== e.target.value) {
			setIsInputChange(true);
			setInputValue(e.target.value);
			dispatch(temperature_changed([{ type: "temperature", changed: true }]));
		} else setIsInputChange(false);
	};

	const verifyTemperatureInput = (e) => {
		e.preventDefault();
		setOnFocus(true);
	};

	const modifyUnitValue = (e) => {
		if (unitValue !== e.target.value) {
			setIsUnitValueChanged(true);
			setUnitValue(e.target.value);
			dispatch(temperature_changed([{ type: "temperature", changed: true }]));
		} else setIsUnitValueChanged(false);
	};

	const isChanged = isInputChange && onFocus ? true : false;

	useEffect(() => {
		if (isChanged || isUnitvalueChanged)  {
			if(!inputValue || isNaN(inputValue)){
				const messageData = [{ type: "temperature", message: "Enter the value for Operating Temperature" }];
				const stateData = [{ type: "temperature", state: false }];
				const valueData = [{ type: "temperature", value: inputValue, unit: unitValue }];

				dispatch(modify_operatingTemparature_message(messageData));
				dispatch(temperature_state(stateData));
				dispatch(temperature_value(valueData));
			}
			else {
				const fetchValidation = async () => {
					const response = await fetch(heat_pipe_api_url + "/OperatingTemperature", {
						method: "POST",
						headers: {
							"Content-Type": "application/json",
						},
						body: JSON.stringify({
							OperatingTemperatureLength: inputValue,
							OperatingTemperatureUnit: unitValue,
						}),
					});

					const resData = await response.json();

					if (response.ok) {
						if (resData.result) {
							const messageData = [{ type: "temperature", message: resData.messages[0] }];
							const stateData = [{ type: "temperature", state: false }];
							const valueData = [{ type: "temperature", value: inputValue, unit: unitValue }];

							dispatch(modify_operatingTemparature_message(messageData));
							dispatch(temperature_state(stateData));
							dispatch(temperature_value(valueData));
							dispatch(display_error_message(false));
						}
						if (!resData.result) {
							const messageData = [{ type: "temperature", message: "Enter the value for Operating Temperature" }];
							const stateData = [{ type: "temperature", state: true }];
							const valueData = [{ type: "temperature", value: inputValue, unit: unitValue }];

							dispatch(modify_operatingTemparature_message(messageData));
							dispatch(temperature_state(stateData));
							dispatch(temperature_value(valueData));
							dispatch(display_error_message(false));
						}
					}
					if (!response.ok) {
						dispatch(
							display_error_message("Oops! Unable to Connect with Server. Please Contact Administrator for further help.")
						);
						throw new Error("Error Validating Value.Please try again!");
					}
				};

				fetchValidation().catch((error) => {
					const messageData = [{ type: "temperature", message: "Error Validating Value. Please try again!" }];
					const stateData = [{ type: "temperature", state: false }];

					dispatch(modify_operatingTemparature_message(messageData));
					dispatch(temperature_state(stateData));
					dispatch(
						display_error_message("Oops! Unable to Connect with Server. Please Contact Administrator for further help.")
					);
				});
			}
		}
	}, [isChanged, inputValue, unitValue, isUnitvalueChanged, dispatch]);

	return (
		<Fragment>
			<form onSubmit={verifyTemperatureInput}>
				<label htmlFor="operatingTemperature">Operating Temperature: </label>
				<input
					id="operatingTemperature"
					type="number"
					onChange={setTemperatureInput}
					onBlur={verifyTemperatureInput}
					required={true}
				/>
				<select onChange={modifyUnitValue}>
					<option value="Celsius">Celsius</option>
					<option value="Fahrenheit">Fahrenheit</option>
				</select>
			</form>
		</Fragment>
	);
};

export default OperatingTemperature;

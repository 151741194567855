import React, { Fragment, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { evaporator_state } from "store/actions/stateActions";
import { evaporator_value } from "store/actions/valueActions";
import { modify_evaporator_message } from "store/actions/messageActions";

import { condensor_state } from "store/actions/stateActions";
import { condensor_value } from "store/actions/valueActions";
import { modify_condensor_message } from "store/actions/messageActions";

import { length_state } from "store/actions/stateActions";
import { length_value } from "store/actions/valueActions";
import { modify_totalLength_message } from "store/actions/messageActions";
import { length_changed } from "store/actions/changedActions";

import { display_error_message } from "store/actions/globalErrorAction";

import heat_pipe_api_url from "constants/index";

const TotalLength = () => {
	const dispatch = useDispatch();

	const valuesArray = useSelector((state) => state.values.overallValues);
	let condensorLengthValue, condensorLengthUnit;
	let evaporatorLengthValue, evaporatorLengthUnit;

	valuesArray.forEach((el) => {
		if (el.type === "condensor") {
			condensorLengthValue = el.value;
			condensorLengthUnit = el.unit;
		}

		if (el.type === "evaporator") {
			evaporatorLengthValue = el.value;
			evaporatorLengthUnit = el.unit;
		}
	});

	const [isInputChange, setIsInputChange] = useState(false);
	const [onFocus, setOnFocus] = useState(false);
	const [unitValue, setUnitValue] = useState("Millimeters");
	const [inputValue, setInputValue] = useState(null);
	const [isUnitvalueChanged, setIsUnitValueChanged] = useState(false);

	const setLengthInput = (e) => {
		if (inputValue !== e.target.value) {
			setIsInputChange(true);
			setInputValue(e.target.value);
			dispatch(length_changed([{ type: "length", changed: true }]));
		} else setIsInputChange(false);
	};

	const verifyLengthInput = (e) => {
		e.preventDefault();
		setOnFocus(true);
	};

	const modifyUnitValue = (e) => {
		if (unitValue !== e.target.value) {
			setIsUnitValueChanged(true);
			setUnitValue(e.target.value);
			dispatch(length_changed([{ type: "length", changed: true }]));
		} else setIsUnitValueChanged(false);
	};

	const isChanged = isInputChange && onFocus ? true : false;

	useEffect(() => {
		if (isChanged || isUnitvalueChanged)  {
			if(!inputValue || isNaN(inputValue)){
				const messageData = [{ type: "length", message: "Enter the value for Total Length" }];
				const stateData = [{ type: "length", state: false }];
				const valueData = [{ type: "length", value: inputValue, unit: unitValue }];
				
				dispatch(length_state(stateData));
				dispatch(length_value(valueData));
				dispatch(modify_totalLength_message(messageData));
			}
			else {
				const fetchValidation = async () => {
					const response = await fetch(heat_pipe_api_url + "/TotalLength", {
						method: "POST",
						headers: {
							"Content-Type": "application/json",
						},
						body: JSON.stringify({
							EvaporatorLength : evaporatorLengthValue || null,
							EvaporatorUnit: evaporatorLengthUnit,
							CondenserLength: condensorLengthValue || null,
							CondenserUnit: condensorLengthUnit,
							TotalLengthValue: inputValue,
							TotalLengthUnit: unitValue,
						}),
					});

					const resData = await response.json();

					if (response.ok) {
						const lenMessage = ( resData.messages[2] == "" || !inputValue || isNaN(inputValue) ) ? "Enter the value for Total Length"  :  resData.messages[2];
						const messageData = [{ type: "length", message: lenMessage }];
						const stateData = [{ type: "length", state: resData.messages[2] == "" }];
						const valueData = [{ type: "length", value: inputValue, unit: unitValue }];
						
						dispatch(length_state(stateData));
						dispatch(length_value(valueData));
						dispatch(modify_totalLength_message(messageData));

						if(evaporatorLengthValue && isNaN(evaporatorLengthValue) == false) {
							const evpMessage = resData.messages[0] == "" ? "Enter the value for Evaporator" : resData.messages[0];
							const evpMessageData = [{ type: "evaporator", message: evpMessage }];
							const evp_stateData = [{ type: "evaporator", state: resData.messages[0] == "" }];
							const evp_valueData = [{ type: "evaporator", value: evaporatorLengthValue, unit: evaporatorLengthUnit }];
							dispatch(evaporator_state(evp_stateData));
							dispatch(evaporator_value(evp_valueData));
							dispatch(modify_evaporator_message(evpMessageData));
						}

						if(condensorLengthValue && isNaN(condensorLengthValue) == false) {
							const condMessage = resData.messages[1] == "" ? "Enter the value for Condenser" : resData.messages[1];
							const condMessageData = [{ type: "condensor", message: condMessage }];
							const cdn_stateData = [{ type: "condensor", state: resData.messages[1] == "" }];
							const cdn_valueData = [{ type: "condensor", value: condensorLengthValue, unit: condensorLengthUnit }];
							dispatch(condensor_state(cdn_stateData));
							dispatch(condensor_value(cdn_valueData));
							dispatch(modify_condensor_message(condMessageData));
						}

						dispatch(display_error_message(false));
					}
					if (!response.ok) {
						dispatch(
							display_error_message("Oops! Unable to Connect with Server. Please Contact Administrator for further help.")
						);
						throw new Error("Error Validating Value.Please try again!");
					}
				};

				fetchValidation().catch((error) => {
					const messageData = [{ type: "length", message: "Error Validating Value. Please try again!" }];
					const stateData = [{ type: "length", state: false }];

					dispatch(modify_totalLength_message(messageData));
					dispatch(length_state(stateData));
					dispatch(
						display_error_message("Oops! Unable to Connect with Server. Please Contact Administrator for further help.")
					);
				});
			}
		}
	}, [isChanged, inputValue, unitValue, isUnitvalueChanged, dispatch]);

	return (
		<Fragment>
			<form onSubmit={verifyLengthInput}>
				<label htmlFor="totalLength">Total Length: </label>
				<input
					id="totalLength"
					type="number"
					onChange={setLengthInput}
					onBlur={verifyLengthInput}
					required={true}
				/>
				<select onChange={modifyUnitValue}>
					<option value="Millimeters">Millimeter</option>
					<option value="Centimeters">Centimeter</option>
					<option value="Inches">Inch</option>
					<option value="Meters">Meter</option>
					<option value="Feet">Feet</option>
				</select>
			</form>
		</Fragment>
	);
};

export default TotalLength;

import React, { Fragment, useState, useEffect } from "react";
import { useDispatch } from "react-redux";

import { diameter_state } from "store/actions/stateActions";
import { diameter_value } from "store/actions/valueActions";
import { diameter_changed } from "store/actions/changedActions";

const PipeDiameter = () => {
	const dispatch = useDispatch();

	const [inputValue, setInputValue] = useState(0);

	const modifyPipeDiameter = (e) => {
		if (inputValue !== e.target.value) {
			setInputValue(+e.target.value);
			dispatch(diameter_changed([{ type: "diameter", changed: true }]));
		}
	};

	useEffect(() => {
		if (inputValue === 0) {
			const stateData = [{ type: "diameter", state: false }];
			const valueData = [{ type: "diameter", value: inputValue, unit: null }];

			dispatch(diameter_state(stateData));
			dispatch(diameter_value(valueData));
		} else {
			const stateData = [{ type: "diameter", state: true }];
			const valueData = [{ type: "diameter", value: inputValue, unit: null }];

			dispatch(diameter_state(stateData));
			dispatch(diameter_value(valueData));
		}
	}, [inputValue, dispatch]);

	return (
		<Fragment>
			<form onSubmit={e => e.preventDefault()}>
				<label htmlFor="pipeDiameter">Pipe Diameter: </label>

				<select className={"diameter_select"} onChange={modifyPipeDiameter}>
					<option value="0">Select</option>
					<option value="0.003">3.00 mm (0.118 in)</option>
					<option value="0.004">4.00 mm (0.157 in)</option>
					<option value="0.0047625">4.76 mm (3/16 in)</option>
					<option value="0.005">5.00 mm (0.197 in)</option>
					<option value="0.006">6.00 mm (0.236 in)</option>
					<option value="0.00635">6.35 mm (1/4 in)</option>
					<option value="0.008">8.00 mm (0.315 in)</option>
					<option value="0.009525">9.53 mm (3/8 in)</option>
					<option value="0.0127">12.70 mm (1/2 in)</option>
					<option value="0.015875">15.88 mm (5/8 in)</option>
					<option value="0.01905">19.05 mm (3/4 in)</option>
					<option value="0.0254">25.40 mm (1 in)</option>
				</select>
			</form>
		</Fragment>
	);
};

export default PipeDiameter;
